import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../layouts/secondary-landing";
import HeroChevron from "../components/hero/hero-chevron";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import getHeroImgVariables from "../helpers/getHeroImgVariables";
import Icon from "../components/custom-widgets/icon";
import BestBanksDefault from "../components/best-banks/best-banks-default";
import FaqAccordion from "../components/faq/faq-accordion";
import StretchedLinkCards from "../components/card/stretched-link-cards";
import Button from "../components/custom-widgets/button";

const LutherBurbankSavings = () => {
  const title = "Welcome Luther Burbank Savings Clients";
  const description =
    "The acquisition of Luther Burbank Savings by WaFd Bank marks a significant milestone for both institutions, and we are eager to welcome you to the WaFd family.";

  const PAGE_DATA = useStaticQuery(graphql`
    query {
      allStrapiCdS(filter: { ProductType: { eq: "DaisyMoneyMarket" } }) {
        nodes {
          APY
          CostTier
          InterestRate
          ProductType
        }
      }
      imgVariableXXL: file(relativePath: { eq: "hero/luther-burbank/hero-luther-burbank-122623-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/luther-burbank/hero-luther-burbank-122623-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/luther-burbank/hero-luther-burbank-122623-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/luther-burbank/hero-luther-burbank-122623-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/luther-burbank/hero-luther-burbank-122623-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/luther-burbank/hero-luther-burbank-122623-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/luther-burbank/hero-luther-burbank-122623-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      CardImage1: file(relativePath: { eq: "cards/thumbnail-personal-banking-122623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage2: file(relativePath: { eq: "cards/thumbnail-business-banking-122623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      CardImage3: file(relativePath: { eq: "cards/thumbnail-builders-of-hope-122623.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "open-bank-account-hero",
    ...getHeroImgVariables(PAGE_DATA),
    altText: "Man and woman carrying a basket of grapes together through a vineyard.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Welcome to WaFd Bank!"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "Please enroll in Online Banking to get started."
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-1",
            text: "Enroll in Online Banking",
            url: "/personal-banking/online-banking?e_campaign=lbs-olb-enrollment&mkt_tok=MzE2LURYUC01NDYAAAGRkAQjtHMGdWQJvF049YHvHiyhFSb5J-L1vm6_eDWdrYTKl4gL5mmhZAwPPwQw7ZeE1Dxg1WNGEziKArC1wQ",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/luther-burbank-savings"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-luther-burbank-122623.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: "true",
      title: "Luther Burbank Savings"
    }
  ];

  const articlesData = {
    sectionClass: "bg-white",
    cards: [
      {
        id: "luther-burbank-card-01",
        url: "/personal-banking",
        titleClass: "mb-4",
        title: "Banking to Fit Your Lifestyle",
        text: "Our suite of personal accounts come with a variety of rewards and benefits, from checking to savings and money market accounts to mortgages. All come with the digital tools you expect to make banking easy.",
        showTextArrow: false,
        image: {
          imgVariable: PAGE_DATA.CardImage1.childImageSharp.gatsbyImageData,
          altText: "Man sitting on a mountain peak enjoying a sunset."
        },
        button: {
          id: "personal-banking-card-cta",
          containerClass: "d-flex flex-grow-1 align-items-end w-100",
          class: "btn-primary w-100",
          showIcon: false,
          text: "Explore Options",
          url: "/personal-banking"
        }
      },
      {
        id: "luther-burbank-card-02",
        url: "/business-banking/small-business",
        titleClass: "mb-4",
        title: "The Best Bank for Business",
        text: "We're here to meet the needs of small businesses, entrepreneurs, and large enterprises alike. From accounts to specialized lending, to all the services you need to run your business smoothly and beyond, WaFd Bank is here for you.",
        showTextArrow: false,
        image: {
          imgVariable: PAGE_DATA.CardImage2.childImageSharp.gatsbyImageData,
          altText: "Entrepreneur working remotely on his laptop."
        },
        button: {
          id: "small-business-card-cta",
          containerClass: "d-flex flex-grow-1 align-items-end w-100",
          class: "btn-primary w-100",
          showIcon: false,
          text: "Explore Options",
          url: "/business-banking/small-business"
        }
      },
      {
        id: "luther-burbank-card-03",
        url: "/about-us/washington-federal-foundation",
        titleClass: "mb-4",
        title: "Making a Difference in Our Communities",
        text: "With a goal of giving at least $1,000,000 annually to 200+ local non-profits, we actively work to improve our impact with thousands of volunteer hours and other efforts.",
        showTextArrow: false,
        image: {
          imgVariable: PAGE_DATA.CardImage3.childImageSharp.gatsbyImageData,
          altText: "WaFd employees presenting a check for Builders of Hope."
        },
        button: {
          id: "washington-federal-foundation-card-cta",
          containerClass: "d-flex flex-grow-1 align-items-end w-100",
          class: "btn-primary w-100",
          showIcon: false,
          text: "Washington Federal Foundation",
          url: "/about-us/washington-federal-foundation"
        }
      }
    ]
  };

  const daisyMMRates = PAGE_DATA.allStrapiCdS.nodes;
  const tier1 = daisyMMRates.find((tier) => tier.CostTier === "$10,000 - $99,999.99");
  const tier2 = daisyMMRates.find((tier) => tier.CostTier === "$100,000 - $249,999.99");
  const tier3 = daisyMMRates.find((tier) => tier.CostTier === "$250,000 - $499,999.99");
  const tier4 = daisyMMRates.find((tier) => tier.CostTier === "$500,000+");

  const faqAccordionData = {
    id: "luther-burbank-savings-faq-accordion",
    sectionClass: "pb-0",
    showContactBar: false,
    faqCategoryNames: ["Luther Burbank Savings"],
    seeMoreFaqsLink: "#treasury-prime-faqs-accordion",
    seeMoreFaqsId: "treasury-prime-faqs-link",
    seeMoreFaqsText: "Looking for Treasury Prime FAQs?",
    seeMoreFaqsIcon: "arrow-down"
  };

  const treasuryPrimeFaqAccordionData = {
    id: "treasury-prime-faqs-accordion",
    title: "Luther Burbank Savings Treasury Prime Frequently Asked Questions",
    showContactBar: true,
    faqCategoryNames: ["Treasury Prime"]
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Welcome Luther Burbank Savings Clients</h1>
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-gray-80">
              WaFd Bank has received approval to acquire Luther Burbank Savings. This marks a significant milestone for
              both institutions, and we are eager to welcome you to the WaFd family. This gives us an opportunity to
              offer you a wider range of banking products and services across a now nine-state footprint! As this merger
              is implemented, we want to thank you for your continued business.
            </h4>
          </div>
          <div className="col-md-6">
            <h4>
              We want to assure you that this transition is guided by a commitment to provide excellent service,
              building strong relationships, and maintain the financial well-being of our customers. Below, you'll find
              answers to some of the most frequently asked questions about the merger and more information about WaFd
              Bank.
            </h4>
            <a
              href="/documents/luther-burbank/lbs-client-conversion-guide-web.pdf"
              className="text-decoration-none"
              id="privacy-policy-pdf"
              target="_blank"
            >
              <Icon name="file-pdf" lib="far" class="mr-1" />
              Luther Burbank Savings to WaFd Bank Conversion Guide
            </a>
            <div className="mt-3 d-block d-md-none">
              <Link
                to="/personal-banking/online-banking"
                className="btn btn-primary btn-block"
                id="internal-page-link-online-banking"
              >
                Enroll in Online Banking
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="about-wafd-bank-section" className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../images/thumbnail-wafd-branch-spokane-122623.jpg"
                alt="WaFd Bank Branch in Spokane, Washington."
                placeholder="blurred"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h3 className="text-success">About WaFd Bank</h3>
              <p className="mb-0">
                WaFd Bank is a regional bank, but we've tried to act more like a reliable next-door neighbor. Ever since
                we got our start back in 1917, we've been helping to build healthy, thriving communities. And, as active
                members of our communities, we're truly invested in clients like you&mdash;delivering straightforward
                banking solutions (including the latest digital tools) to help you better manage your money. At WaFd
                Bank we share common values with Luther Burbank Savings; a focus on customer satisfaction, community
                involvement, and financial responsibility. Our commitment to these principles is unwavering, and we look
                forward to upholding the traditions and standards that matter most to you.
              </p>
            </div>
          </div>
        </div>
      </section>

      <StretchedLinkCards {...articlesData} />

      <section id="daisy-money-market-section" className="bg-light">
        <div className="container">
          <h2>WaFd Daisy Money Market</h2>
          <div className="table-responsive d-none d-md-block">
            <table className="table table-bordered mb-0">
              <thead className="thead-gray">
                <tr>
                  <th id="cost-tier-1">{tier1.CostTier}</th>
                  <th id="cost-tier-2">{tier2.CostTier}</th>
                  <th id="cost-tier-3">{tier3.CostTier}</th>
                  <th id="cost-tier-4">{tier4.CostTier}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="cost-tier-1-interest-rate">{parseFloat(tier1.InterestRate).toFixed(2)}% Interest Rate</td>
                  <td id="cost-tier-2-interest-rate">{parseFloat(tier2.InterestRate).toFixed(2)}% Interest Rate</td>
                  <td id="cost-tier-3-interest-rate">{parseFloat(tier3.InterestRate).toFixed(2)}% Interest Rate</td>
                  <td id="cost-tier-4-interest-rate">{parseFloat(tier4.InterestRate).toFixed(2)}% Interest Rate</td>
                </tr>
                <tr>
                  <td id="cost-tier-1-apy">{parseFloat(tier1.APY).toFixed(2)}% Annual Percentage Yield</td>
                  <td id="cost-tier-2-apy">{parseFloat(tier2.APY).toFixed(2)}% Annual Percentage Yield</td>
                  <td id="cost-tier-3-apy">{parseFloat(tier3.APY).toFixed(2)}% Annual Percentage Yield</td>
                  <td id="cost-tier-4-apy">{parseFloat(tier4.APY).toFixed(2)}% Annual Percentage Yield</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="d-block d-md-none">
            <table className="table table-bordered mb-0">
              <thead className="thead-gray">
                <tr>
                  <th id="cost-tier-1">{tier1.CostTier}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="cost-tier-1-interest-rate">{parseFloat(tier1.InterestRate).toFixed(2)} Interest Rate</td>
                </tr>
                <tr>
                  <td id="cost-tier-1-apy">{parseFloat(tier1.APY).toFixed(2)} Annual Percentage Yield</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered mb-0">
              <thead className="thead-gray">
                <tr>
                  <th id="cost-tier-1">{tier2.CostTier}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="cost-tier-2-interest-rate">{parseFloat(tier2.InterestRate).toFixed(2)} Interest Rate</td>
                </tr>
                <tr>
                  <td id="cost-tier-2-apy">{parseFloat(tier2.APY).toFixed(2)} Annual Percentage Yield</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered mb-0">
              <thead className="thead-gray">
                <tr>
                  <th id="cost-tier-1">{tier3.CostTier}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="cost-tier-3-interest-rate">{parseFloat(tier3.InterestRate).toFixed(2)} Interest Rate</td>
                </tr>
                <tr>
                  <td id="cost-tier-3-apy">{parseFloat(tier3.APY).toFixed(2)} Annual Percentage Yield</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-bordered mb-0">
              <thead className="thead-gray">
                <tr>
                  <th id="cost-tier-1">{tier4.CostTier}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td id="cost-tier-4-interest-rate">{parseFloat(tier4.InterestRate).toFixed(2)} Interest Rate</td>
                </tr>
                <tr>
                  <td id="cost-tier-4-apy">{parseFloat(tier4.APY).toFixed(2)} Annual Percentage Yield</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Button
            id="wafd-bank-account-rates-cta"
            containerClass="mt-4"
            class="btn-primary"
            showIcon={false}
            text="WaFd Bank Account Rates"
            url="/personal-banking/interest-rates"
          />
          <div className="text-gray-60 text-sm mt-4">
            <p className="mb-0">
              This is not an account currently offered and is only available for clients affected by the WaFd Bank
              acquisition of Luther Burbank Savings.
            </p>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
      <FaqAccordion {...treasuryPrimeFaqAccordionData} />

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default LutherBurbankSavings;
